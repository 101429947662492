import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConverMoeny } from "../utility/help";
import { UserService } from "../service/user.service";
import { SlotService } from "../service/slot.service";
import { Deposit } from "./deposit";
import { Withdraw } from "./withdraw";
import { Help } from "./help";
import { User } from "./user";
import { Notice } from "./notice";
import { Reg } from "./reg";
import { Login } from "./login";
import { Point } from "./point";
import { Comp } from "./comp";
import { Note } from "./note";
import Marquee from "react-fast-marquee";

export enum popupView {
  none = "none",
  deposit = "deposit",
  comp = "comp",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
}

async function sleep(ms: any) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

interface Props {
  user: any;
  activeHelp: boolean;
  authenticated: boolean;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;
  updateUser: () => any;
  //   session: any;
}

interface State {
  ID: string;
  PW: string;
  balance: number;
  postCount: number;
  noteCount: number;
  note: any;
  popupStatuses: string;
  expanded: string;
  notes: any;
}

export class Header extends Component<Props, State> {
  userService = new UserService();
  slotService = new SlotService();

  constructor(props: Props) {
    super(props);
    this.state = {
      balance: 0,
      ID: "",
      PW: "",
      postCount: 0,
      note: [],
      notes: [],
      popupStatuses: popupView.none,
      noteCount: 0,
      expanded: "",
    };

    this.handleUpdateNote();

    setImmediate(async () => {
      try {
        for (;;) {
          this.handleUpdateNote();

          await sleep(10000);
        }
      } catch (ex) {}
    });
    // this.updateUser();
  }

  componentDidMount() {
    this.handleUpdateInBalance();
  }

  handleUpdateInBalance = () => {
    this.slotService.get_in_game_balance().then((data: any) => {
      if (data.status === "success") {
        this.setState({
          balance: data.balance ?? 0,
        });
        this.props.user.balance = data.balance ?? 0;
      } else {
      }
    });
  };

  handleUpdateNote = () => {
    this.userService.get_user_note().then((data: any) => {
      if (data.status === "success") {

        if(data.count > 0 && this.state.postCount === 0){
          this.setState({
            postCount: data.count,
            note: data.note,
            popupStatuses : popupView.note
          });

        }else if( this.state.popupStatuses !==  popupView.note){
          this.setState({
            postCount: 0,
            note: [],
          });
  
        }


        // if (this.state.postCount > 0) {
        //   this.setState({ popupStatuses: popupView.note });
        // }
      } else if (this.props.authenticated) {
        this.props.tryLoginOut();
      }
    });
  };

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  handleChangePopup = (viewName: string) => {
    this.setState({ popupStatuses: viewName });
  };

  handleReadNote = (id: string) => {
    if (this.props.authenticated == true) {
      this.userService.do_read_note(id).then((data: any) => {
        if (data.status === "success") {
          let note = this.state.note.find((read: any) => read._id === id);
          if (note != null) {
            note.isRead = "y";
            this.setState({
              note: this.state.note,
            });
          }
        }
      });
    }
  };

  do_del_all_note = () => {
    if (this.props.authenticated == true) {
      this.userService.do_del_all_note().then((data: any) => {
        window.location.reload();
      });
    }
  };

  do_read_all_note = () => {
    if (this.props.authenticated == true) {
      this.userService.do_read_all_note().then((data: any) => {
        window.location.reload();
      });
    }
  };

  handleDel = (id: string) => {
    if (this.props.authenticated == true) {
      this.userService.do_del_note(id).then((data: any) => {
        if (data.status === "success") {
          this.setState({
            notes: data.note,
          });
          this.handleUpdateNote();
        }
      });
    }
  };

  changePopup = (value: any) => {
    this.setState({ popupStatuses: value });
  };

  render() {
    const user = this.props.user;
    const authenticated = this.props.authenticated;

    const RenderPopup = () => {
      if (this.state.popupStatuses === popupView.deposit) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Deposit
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            user={this.props.user}
            updateUser={this.props.updateUser}
          ></Deposit>
        );
      }

      if (this.state.popupStatuses === popupView.withdraw) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Withdraw
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Withdraw>
        );
      }

      
      if (this.state.popupStatuses === popupView.comp) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        
        return (
          <Comp
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Comp>
        );
      }

      if (this.state.popupStatuses === popupView.notice) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Notice
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Notice>
        );
      }
      
   
      if (this.state.popupStatuses === popupView.note) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Note
            handleClosePopup={this.handleClosePopup}
            handleChange={this.handleChangePopup}

            // handleDel={this.handleDel}
            // handleReadNote={this.handleReadNote}
            note={this.state.note}
          ></Note>
        );
      }

      if (this.state.popupStatuses === popupView.point) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Point
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Point>
        );
      }

      if (this.state.popupStatuses === popupView.reg) {
        return <Reg handleClose={this.handleClosePopup}></Reg>;
      }

      if (this.state.popupStatuses === popupView.login) {
        return (
          <Login
            handleClose={this.handleClosePopup}
            tryLogin={this.props.tryLogin}
            changePopup={this.changePopup}
          ></Login>
        );
      }

      if (this.state.popupStatuses === popupView.help) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Help
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Help>
        );
      }
      if (this.state.popupStatuses === popupView.user) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <User
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            user={this.props.user}
          ></User>
        );
      }

      return <div></div>;
    };

    const RendePopup = () => {
      if (0 < this.state.postCount) {
        this.setState({ popupStatuses: popupView.note });
      }

      // this.setState({ popupStatuses: popupView.note });

      return <div></div>;
    };

    const RenderLogin = () => {
      if (authenticated === false) {
        return (
          <>
            <div className="login">
              <ul>
                <li>
                  <input
                    className="input_login"
                    placeholder="아이디"
                    type="text"
                    id="login_id"
                    name="login_id"
                    onChange={({ target: { value } }) =>
                      this.setState({ ID: value })
                    }
                  />
                </li>
                <li>
                  <input
                    className="input_login"
                    placeholder="비밀번호"
                    type="password"
                    id="login_pass"
                    name="login_pass"
                    onChange={({ target: { value } }) =>
                      this.setState({ PW: value })
                    }
                  />
                </li>
                <li>
                  <a
                    
                    onClick={() => {
                      if (
                        this.state.ID == null ||
                        this.state.ID == "" ||
                        this.state.PW == "" ||
                        this.state.PW == null
                      ) {
                        return;
                      }
                      this.props.tryLogin(this.state.ID, this.state.PW);
                    }}
                  >
                    <img src="/images/login_btn1.png" />
                  </a>
                </li>
                <li>
                  <a
                    
                    className="casino_1_open"
                    data-popup-ordinal="0"
                    onClick={() => {
                      this.setState({ popupStatuses: popupView.reg });
                    }}
                  >
                    <img src="/images/login_btn2.png" />
                  </a>
                </li>
              </ul>
            </div>
          </>
        );
      }

      return (
        <>
          <div className="login">
            <ul>
              <li>
              <a
                  data-toggle="modal"
                  data-target="#board_contact-modal"
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.note });
                  }}
                >
                  <img src="/images/nav_icon_02.png" height="16" /> 
                  <span className="stat_rectangle px-3 py-1" id="">쪽지 ({this.state.postCount})</span>
                </a> 

              </li>
              <li>&nbsp;{this.props.user.id}님 환영합니다.</li>
              <li>
                <span style={{ float: "right" }}>
                  <img src="/images/ww_icon.png" height="16" /> 보유머니{" "}
                  <span className="font05" id="lnOwnMoney">
                    {ConverMoeny(this.state.balance)}
                  </span>{" "}
                  &nbsp;&nbsp;
                </span>
              </li>
              <li>
                <a
                  
                  onClick={() => {
                    this.props.tryLoginOut();
                  }}
                >
                  <img src="/images/login_btn4.png" />
                </a>
              </li>
            </ul>
          </div>
        </>
      );
    };

    return (
      <>
        <div id="header_wrap">
          <div className="logo">
            <a >
              <img
                src="/images/logo.png?v=1639256876"
                className="clsLogoImage"
              />
            </a>
          </div>
          <div className="gnb_wrap">
            <div className="gnb">
              <ul>
                <li>
                  <a
                    
                    onClick={() => {
                      this.setState({ popupStatuses: popupView.deposit }); //입금신청
                    }}
                  >
                    {/* <img src="/images/menu05.png" /> */}
                    <p className="s3">입금신청</p>
                  </a>
                </li>
                <li>
                  <a
                    
                    onClick={() => {
                      this.setState({ popupStatuses: popupView.withdraw }); //출금신청
                    }}
                  >
                    {/* <img src="/images/menu04.png" /> */}
                    <p className="s3">출금신청</p>
                  </a>
                </li>
                <li>
                  <a
                    
                    onClick={() => {
                      this.setState({ popupStatuses: popupView.point }); //금고
                    }}
                  >
                    {/* <img src="/images/menu08.png" /> */}
                    <p className="s3">금고</p>
                  </a>
                </li>
                <li>
                  <a
                    
                    onClick={() => {
                      this.setState({ popupStatuses: popupView.comp }); //금고
                    }}
                  >
                    {/* <img src="/images/menu08.png" /> */}
                    <p className="s3">콤프</p>
                  </a>
                </li>
                <li>
                  <a
                    
                    onClick={() => {
                      this.setState({ popupStatuses: popupView.help }); //고객센터
                    }}
                  >
                    {/* <img src="/images/menu07.png" /> */}
                    <p className={this.props.activeHelp ? 's3 neon' : 's3'}>고객센터</p>
                  </a>
                </li>
                <li>
                  <a
                    
                    
                    // className={this.props.activeHelp ? 'neon' : ''} 
                    onClick={() => {
                      this.setState({ popupStatuses: popupView.user }); //마이페이지
                    }}
                  >
                    {/* <img src="/images/menu02.png" /> */}
                    <p className={'s3'} >마이페이지</p>
                  </a>
                </li>
                <li>
                  <a
                    
                    onClick={() => {
                      this.setState({ popupStatuses: popupView.notice }); //공지사항
                    }}
                  >
                    {/* <img src="/images/menu03.png" /> */}
                    <p className="s3">공지사항</p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="login_wrap">
            <div className="login_box">
              <div className="notice_wrap">
                <div className="notice" style={{ paddingLeft: "50px" }}>
                  {/* <marquee id="NewsMarQuee" onmouseover="stop()" style="MARGIN-TOP:0px; WIDTH:440px; HEIGHT:38; margin:0 0 0 50px;" onmouseout="start()" scrollamount="8" scrolldelay="5" direction="left">
                                    입금전 필히 계좌문의 해주시기바랍니다. 전계좌입금으로인한 불이익은 당사가 책임지지않습니다.
                                </marquee> */}
                  <Marquee
                    direction="left"
                    delay={5}
                    style={{ width: "440px" }}
                    gradientColor={[0, 0, 0]}
                  >
                    입금전 필히 계좌문의 해주시기바랍니다. 전계좌입금으로인한
                    불이익은 당사가 책임지지않습니다.
                  </Marquee>
                </div>
              </div>

              {RenderLogin()}
            </div>
          </div>
        </div>

        {RenderPopup()}
        {/* {RendePopup()} */}
      </>
    );
  }
}
